<template>
    <div>

        <BasicNewContainer
            :title="t('create.title')"
            ref="editor"
            @cancel="close"
            :saveAction="saveItem"
            @saved="onSaved"
            :has-changes="hasChanges"
        >
            <template>
                <v-card elevation="0">
                    <v-card-title>{{ t('details.group-title') }}</v-card-title>
                    <v-card-text>

                        <v-text-field
                            :label="t('details.title') + '*'"
                            counter="120"
                            v-model="data.title"
                            data-cy="title"
                            filled
                            :rules="[
                                () => !!data.title || $t('validation.required.title'),
                                () => (data.title && data.title.length <= 120) || $t('validation.length.title')
                            ]"
                        ></v-text-field>

                        <v-textarea
                            :label="t('details.description')+'*'"
                            v-model="data.description"
                            data-cy="description"
                            filled
                            :rules="[
                                () => !!data.description || $t('validation.required.description')
                            ]"
                        ></v-textarea>

                    </v-card-text>
                </v-card>

                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('picture.group-title') }}</v-card-title>
                    <v-card-text>

                        <picture-upload v-model="data.picture"></picture-upload>

                    </v-card-text>
                </v-card>

            </template>

            <template v-slot:meta>
                <!-- Kategorie -->
                <v-card elevation="0">
                    <v-card-title>{{ t('category.group-title') }}*</v-card-title>
                    <v-card-text>

                        <v-select
                            filled
                            :title="t('category.hint')"
                            :label="t('category.hint')"
                            v-model="data.category_id"
                            :items="taskCategories"
                            data-cy="category_id"
                            item-text="name"
                            item-value="id"
                            :rules="[
                                () => !!data.category_id || $t('validation.required.category')
                            ]"
                        ></v-select>

                    </v-card-text>
                </v-card>

                <!-- Zuweisung Customer -->
                <v-card
                    v-if="moduleEnabled('customers')"
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('customer-assignment.group-title') }}</v-card-title>
                    <v-card-text>
                        <content-filter-customer
                            :get-search-url="getCustomerSearchUrl"
                            data-cy="customer_id"
                            @item-selected="customerSelected"/>
                    </v-card-text>
                </v-card>

                <!-- Zuweisung Mitarbeiter -->
                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('employee-assignment.group-title') }}*</v-card-title>
                    <v-card-text>
                        <content-filter-employee
                            @item-selected="userSelected"
                            :rules="[v => !!v || $t('validation.required.user')]"
                        />
                    </v-card-text>
                </v-card>

                <!-- Erledigungsdatum -->
                <v-card
                    elevation="0"
                    class="sp-mar-top-3"
                >
                    <v-card-title>{{ t('due-date.group-title') }}*</v-card-title>
                    <v-card-text>
                        <app-date-time-picker
                            :label="t('due-date.date')"
                            v-model="data.due_date"
                            date-format="dd.MM.yyyy"
                            data-cy="due_date"
                            :clearable="false"
                            :rules="[v => !!v || $t('validation.required.due-date')]"
                            :id="'dueDate'"
                            :required="true"     
                        ></app-date-time-picker>
                    </v-card-text>
                </v-card>
            </template>
        </BasicNewContainer>

    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { hasModule } from '@/services/LicenseService'
import AppSearchField from '@/components/vuetify/AppSearchField'
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import PictureUpload from '@/components/inputs/PictureUpload'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'

export default {
    name: 'TasksCreate',
    components: {
        ContentFilterCustomer,
        BasicNewContainer,
        ContentFilterEmployee,
        PictureUpload,
        AppSearchField,
        AppDateTimePicker
    },
    data() {
        return {
            dataEmpty: null,
            data: {
                title: '',
                description: '',
                assigned_user: null,
                assigned_customer: null,
                due_date: null,
                category_id: null,
                picture: null,
            },
            dataOriginal: null,
            createdItemId: null,
            taskCategories: [],
            assignedUser: null,
            assignedCustomer: null,
            isSubmitted: false
        }
    },
    created() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
        this.getTaskCategories()
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        },
    },
    methods: {
        t: function (key) {
            return this.$t('tasks.detail.' + key)
        },
        moduleEnabled(string) {
            return hasModule(string)
        },
        getTaskCategories() {
            HTTP.get('tasks/categories?TasksOnly=1').then(function (response) {
                response.data.categories.forEach((element) => {
                    this.taskCategories.push({id: element.id, name: element.name})
                })
                this.fireWatcherForDataChange()
            }.bind(this)).catch(function (error) {
                if (!error.status) {
                    this.error = true
                    this.dataLoaded = false
                }
            }.bind(this))
        },
        /*async sendPush(insertId) {
            const config = {
                headers: {'content-type': 'application/json'}
            };

            var pushData = {}
            pushData.id = insertId
            pushData.type = 'tasks'
            pushData.source = 'request'

            return HTTP.post('push', pushData, config)
        },*/
        saveItem() {
            return HTTP.post('tasks', JSON.stringify(this.data))
        },
        async onSaved(insertId) {
            // await this.sendPush(insertId)
            this.close()
        },
        close() {
            this.$router.push({ name: 'Pendenzen' })
        },
        userSelected(id, name) {
            this.assignedUser = name
            this.data.assigned_user = id
        },
        customerSelected(id, name) {
            this.assignedCustomer = name
            this.data.assigned_customer = id
        },
        getCustomerSearchUrl(val) {
            return 'tasks/customers?q=' + val
        }
    },
}
</script>

<style lang="scss" scoped>
.thumbnail-area {
  margin-bottom: 16px;

  img {
    border: 1px solid rgba(227, 227, 227, .7);
  }
}

.user-assignment,
.customer-assignment {
  position: relative;
  width: 342px;
  padding-right: 80px;
  margin-top: 8px;
  padding-left: 12px;
  line-height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  button {
    position: absolute;
    right: 0;
  }
}

.customer-assignment {

  button {
    position: absolute;
    right: 0;
  }
}
</style>

